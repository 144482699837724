<template>
    
    <v-card color="blue-darken-4" flat height="70px"  rounded="0" >
      <v-progress-linear v-if="carging"
            color="green"
            indeterminate
            rounded
            height="8"
          ></v-progress-linear>

    <v-toolbar  color="blue-darken-4">
      <v-btn @click="nuevo" prepend-icon="mdi-plus">
        Nuevo
      </v-btn>
<!-- 
      <v-btn icon >
        <v-icon>mdi-reload</v-icon>
      </v-btn> -->

      <v-btn prepend-icon="mdi-home-variant-outline"  v-if="OF != ''" @click="OF = ''; tab='prot';">
        Home
      </v-btn>


      <v-btn prepend-icon="mdi-arrow-down-circle" v-if="OF != '' && OF != 'nuevo'" @click="dialog.advertencia= true">
        Descargar
      </v-btn>

      <!-- <v-btn  prepend-icon="mdi-lead-pencil" v-if="OF != ''" @click="dialog.firma = true; dialog.dto = items.OF; dialog.dto2 = items.APROBO  != null  ? 'Aprobado' : items.REVISO != null ? 'Revisado' : 'Elavorado';">
        Firmar
      </v-btn> -->

      
      <v-btn  prepend-icon="mdi-content-save" @click="guardar" v-if="tab != 'prot'">
        Guardar
      </v-btn>

      
      <v-btn prepend-icon="mdi-share-variant" v-if="OF != '' && OF != 'nuevo'" @click="dialog.share = true" >
       
      </v-btn>




      <v-spacer></v-spacer>


      
      <v-btn  v-if="OF != '' && sectionLog == 'Ingenieria'" >{{ sectionView }}</v-btn>

    </v-toolbar>
  </v-card>

  <v-tabs v-if="OF == '' && sectionLog == 'Ingenieria'" v-model="sectionView">
      <v-tab value="Ingenieria" @click="ListMain = this.ingenieria" >Ingenieria</v-tab>
      <v-tab value="produccion" @click="ListMain = this.produccion" >Produccion</v-tab>
      <v-tab value="corezapata" @click="this.ListMain = this.zapata" >Core Zapata</v-tab>
  </v-tabs>

  <v-table v-if="OF == ''">
   
      <thead>
        <tr>
          <th  v-for="item in headers" class="text-center" style="font-size: small;"
          :key="item" >{{ item }}</th>
        </tr>
      </thead>
      <tbody>
        <tr  v-for="items in ListMain"  :key="items" style="font-size: small;" >
          <td color="cyan-accent-3" style="cursor: pointer; color:#00E5FF;" @click="OF = items.OF; ID = items.ID; obtenerxOF();" class="text-center">{{ items.OF }}</td> 
          <td class="text-center" >{{ items.CLIENTE }}</td>
          <td class="text-center" >{{ items.CANTIDAD }}</td>
          <td class="text-center" >{{ items.MODELO }}</td>
          <td class="text-center" >{{ items.OC }}</td>
          <td class="text-center" >{{ items.FECHA }}</td>
          <td class="text-center">{{ items.APROBO  != null  ? 'Aprobado' : items.REVISO != null ? 'Revisado' : 'Elavorado' }}</td>
          <td class="text-center">
            <v-btn icon="mdi-lead-pencil" color="purple-darken-4" @click="dialog.firma = true; dialog.dto = items.OF; dialog.dto2 = items.APROBO  != null  ? 'Aprobado' : items.REVISO != null ? 'Revisado' : 'Elavorado';"></v-btn>
             <v-btn icon="mdi-file-download-outline"  color="light-blue-darken-4"  style="margin-right: 10px;" @click=" OF=items.OF; ID = items.ID;   obtenerxOF(); dialog.advertencia= true;" ></v-btn>
            <v-btn icon="mdi-delete" @click="dialog.confirmasion = true; dialog.dto = items.OF;" color="red-accent-4" ></v-btn>
          </td>  
        </tr>
      </tbody> 
   </v-table>




   <v-row v-else>
    <v-card style="width: 15%; overflow: auto; height: 90vh;" v-if="tab == 'prot'" >
      
      <v-list lines="one" >
          <v-list-item
          v-for="items in ListMain"
          active-color="blue-darken-4 "
          :key="items.OF"
          :title="items.OF"
          :subtitle="items.MODELO"
          @click="OF = items.OF; ID = items.ID;  obtenerxOF();"
          variant="tonal">
          <v-progress-linear color="indigo-darken-4" v-if="items.OF == OF && items.ID == ID " model-value="100" :height="7"></v-progress-linear>
          </v-list-item>
      </v-list>
    </v-card>
    <v-card id="conetido" class="contenido">
      <v-tabs v-model="tab">
        <v-tab value="prot" @click="verventata" v-if="OF != '' && OF != 'nuevo'">  Protocolos   </v-tab>
        <v-tab value="gen"  @click="verventata">  General   </v-tab>
        <!-- <v-tab value="adv" @click="verventata">  Avanzado   </v-tab> -->
      </v-tabs>

    
      
      <v-window v-model="tab" class="contentof">
          <v-window-item value="prot">
            <v-row style="margin-top: 5px;" >
              <v-card width="15%" style="overflow: auto; height: 84vh;">
                <v-list density="compact"  v-if="General.Secundario != 'doble' && General.TPOTRANS != 'TRIFASICO'"  >
                  <v-list-item style="cursor: pointer;"
                  v-for="items in  Detalles.data"  
                  :key="items.NS"
                  :title="items.NS"
                  :subtitle="'R_ref = '+items.R_ref" 
                  @click="NS = items.NS; geturl(items.NS, false, clasedt)"
                  active-color="primary">

                  <v-list-item-title :v-text="items.NS">
                    <v-progress-linear color="indigo-darken-4" v-if="items.NS == NS && items.APLICATION == clasedt" model-value="100" :height="7"></v-progress-linear></v-list-item-title>
                  </v-list-item>
                </v-list>


                <v-list density="compact"  v-else >
                  <v-list-item style="cursor: pointer;"
                  v-for="items in  Detalles.series"                 
                  :key="items"
                  :title="items"
                  @click="NS = items; geturl(items, false, clasedt)"
                  active-color="primary">

                  <v-list-item-title :v-text="items">
                    <v-progress-linear color="indigo-darken-4" v-if="items == NS" model-value="100" :height="7"></v-progress-linear></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
                 <v-card  class="iframeRpt">
                  <v-row style="width: 100%; justify-content: left; margin-top: 10px; margin-bottom: 10px; margin-left: 10px;">
                     {{ NS }}
                    <v-btn variant="text" style="margin-left: 10px; margin-top: -10px;" @click="geturl(NS, true)">{{  this.leng == 'Español' ?  'Ingles': 'Español'}}</v-btn>
                  </v-row>
                     <iframe  :src="SRCprotocolo" frameborder="0" class="iframeRpt" style="width: 100%; height: 97%;"></iframe>
                  </v-card>
             </v-row>
          </v-window-item>
          <v-window-item value="gen">
            <v-row style="width: 100%; ">
              <v-card style="font-size: small; width: 75%; margin: 10px; height: 80vh; overflow: auto; padding-top: 20px;" >
                <v-card>
                    <v-card-tittle>Tabla de datos generales</v-card-tittle>
                      <v-table style="margin-bottom: 30px;">
                        <tbody  style="font-size: 11px; padding: 0; ">
                          <tr v-if="sectionView != 'corezapata'">
                            <td class="col1">Modelo de TC</td>
                            <td colspan="2"> <v-text-field label="Modelo" v-model="General.modelo"></v-text-field></td>
                            <td class="col1">No. de Serie:</td>
                            <td><v-text-field label="No de serie" v-model="General.no_serie"></v-text-field></td>
                          </tr>
                          <tr >
                            <td>Orden de Compra: </td>
                            <td colspan="2"> <v-text-field label="Orden de compra" v-model="General.OC"></v-text-field></td>
                            <td v-if="sectionView != 'corezapata'">Factor térmico continuo 30°C AMB:</td>
                            <td v-else >Factor térmico/Cap. termica</td>
                            <td> <v-text-field label="Factor termico" v-model="General.FT"></v-text-field></td>
                          </tr> 
                          <tr >
                            <td>Evaluación general: </td>
                            <td><v-text-field label="Egeneral" v-model="General.EG"></v-text-field></td>
                            <td>Cliente:</td>
                            <td  colspan="2"><v-text-field label="Cliente" v-model="General.CT"></v-text-field></td>
                          </tr> 
                          <tr >
                            <td>Cantidad: </td>
                            <td><v-text-field label="Cantidad" v-model="General.Cantidad"></v-text-field></td>
                            <td></td>
                            <td>Clase de aislamiento: </td>
                            <td><v-text-field label="Clas aislamiento" v-model="General.CLASIS"></v-text-field></td>
                          </tr>   
                          <tr >
                            <td>Tipo de transformador: </td>
                            <td colspan="2"><v-select label="Tipo de transformador" :items="['BUSHING', 'VENTANA', 'TRIFASICO', 'DONA']"  v-model="General.TPOTRANS"></v-select>
                            </td>
                            <td>OF: </td>
                            <td><v-text-field label="OF" v-model="General.OF"></v-text-field></td>
                          </tr>        
                        </tbody> 
                    </v-table>
              </v-card>


              <v-card style="margin-top: 60px;">
                <v-card-tittle v-if="sectionView != 'corezapata'">Tabla de activos</v-card-tittle>
                  <v-table style="margin-bottom: 30px;">
                    <thead>
                      <tr>
                        <th class="col1" v-if="sectionView != 'corezapata'">Potencial Aplicado</th>
                        <th class="col1" v-else >KV primario</th>
                        <th><v-text-field label="PA" v-model="General.PA"></v-text-field></th>
                        <th class="col1" v-if="sectionView != 'corezapata'">Potencial Inducido</th>
                        <th class="col1" v-else >V Inducido</th>
                        <th><v-text-field label="PI" v-model="General.PI"></v-text-field> </th>
                      </tr>
                    </thead>
                    <tbody  style="font-size: 11px; padding: 0; ">
                      <tr >
                        <!-- <td class="col1">Aplicacion </td> -->
                        <td class="col1" v-if="sectionView != 'corezapata'">Aplicacion</td>
                        <td class="col1" v-else >Tipo de pruebas</td>
                        <td v-if="General.Secundario != 'doble' && sectionView != 'corezapata'"><v-select label="Aplicacion" :items="['Medicion', 'Proteccion', 'Proteccion/Medicion']"  v-model="General.Aplication"></v-select> </td>
                        <td v-if="General.Secundario == 'doble' && sectionView != 'corezapata'"><v-select label="Aplicacion" :items="['Proteccion/Medicion','Proteccion/Proteccion', 'Medicion/Medicion']"  v-model="General.Aplication"></v-select> </td>
                        <td v-if="sectionView == 'corezapata'"><v-select label="Tipo de pruebas" :items="['Informe de prueba VT(2pg)','Informe de prueba TC(3pg)', 'Informe doble secundario(6pg)']"  v-model="General.Aplication"></v-select> </td>
                          
                        <!-- <td  class="col1">Clase</td> -->
                        <td class="col1" v-if="sectionView != 'corezapata'">Clase</td>
                        <td class="col1" v-else >KV secundario</td>
                        <td><v-text-field  v-model="General.Clase"></v-text-field> </td>
                      </tr> 
                      <tr v-if="sectionView != 'corezapata'">
                        <td>Frecuencia</td>
                        <td > <v-text-field label="Frecuencia" v-model="General.Frecuancia"></v-text-field></td>
                        <td>Norma:</td>
                        <td>
                          <v-select label="Norma" :items="['', 'ANSI/IEEE C57.13', 'IEC-61869-2', 'IEC-61869-2 / VE100-13', 'CAN3-C13-M83', 'CAN/CSA C61869-2', 'CAN/CSA –C60044-1 / IEC60044-6 / ANSI/IEEE C57.13', 'ANSI/IEEE C57.13 / CAN3-C13-M83 / NEMA SG-4', 'AS 60044.1', 'IEC-61869-2 / NFR-027-CFE', 'ANSI/IEEE C57.13 / CAN3-C13-M83', 'CAN CSA 60044-1', 'CAN / IEC / 61869-2 / IEEE C57.13']"  
                          v-model="General.Norma"></v-select></td>
                      </tr>
                      
                    </tbody> 
                </v-table>
              </v-card>


              
              <v-card style="margin-top: 60px;" v-if="sectionView != 'corezapata'">
                <v-card-tittle>OTROS DATOS</v-card-tittle>
                  <v-table style="margin-bottom: 30px;">
                    <thead>
                      <tr>
                        <th class="col1">Terminales</th>
                        <th><v-select label="Terminales" :items="['default','X', 'S', 'Y']" v-model="General.Terminales" ></v-select></th>
                        <th class="col1">Relaciones</th>
                        <th><v-select label="Relaciones" :items="['1-2', '1-3', '1-4', '1-5', '2-3', '2-4', '2-5', '3-4', '3-5', '4-5']" v-model="General.Relaciones"></v-select></th>
                      </tr>
                    </thead>
                    <tbody  style="font-size: 11px; padding: 0; ">
                      <tr >
                        <td class="col1">SECUNDARIO </td>
                        <td><v-select label="SECUNDARIO" :items="['simple', 'doble']" v-model="General.Secundario"></v-select>
                            </td>
                          
                      </tr> 
                      
                    </tbody> 
                </v-table>
              </v-card>

              </v-card>

         

<v-card  style="width: 23%; height: 87vh;">
             <v-file-input v-if="OF== 'nuevo'" style="margin-top: 40px; width: 100%; "
                  v-model="files"
                  id="files"
                    color="deep-purple-accent-4"
                    counter
                    :label="General.Secundario == 'doble' ? 'Proteccion' : 'Protocolos'"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    variant="outlined"
                    :show-size="1000"
                  >
            <template v-slot:selection="{ fileNames }">
            <template v-for="(fileName, index) in fileNames" :key="fileName">
              <v-chip
                v-if="index < 2"
                color="deep-purple-accent-4"
                label
                size="small"
                class="me-2"
              >
                {{ fileName }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline text-grey-darken-3 mx-2"
              >
                +{{ files.length - 2 }} File(s)
              </span>
            </template>
          </template>
        </v-file-input>


        <v-file-input v-if="OF== 'nuevo' && General.Secundario == 'doble'" style="margin-top: 40px; width: 100%; "
                  v-model="files2"
                  id="files2"
                    color="deep-purple-accent-4"
                    counter
                    label="Medicion"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    variant="outlined"
                    :show-size="1000"
                  >



                    <template v-slot:selection="{ fileNames }">
            <template v-for="(fileName, index) in fileNames" :key="fileName">
              <v-chip
                v-if="index < 2"
                color="deep-purple-accent-4"
                label
                size="small"
                class="me-2"
              >
                {{ fileName }}
              </v-chip>

              <span
                v-else-if="index === 2"
                class="text-overline text-grey-darken-3 mx-2"
              >
                +{{ files2.length - 2 }} File(s)
              </span>
            </template>
          </template>
        </v-file-input>








    <v-list v-if="OF!= 'nuevo'" lines="one"  style="margin-top: 40px;  height: 87vh; overflow: auto;">
                  <v-crad v-for="(items , index) in Detalles.data" :key="items.NS"  style="width: 100%; " > 
                    <div v-if="General.Secundario == 'doble'" >
                    {{ items.APLICATION }}</div>  
                    <v-card-tittle style="display: flex;">                     
                       <div style="width: 20%;"> {{ General.no_serie }}-</div> 
                       <input type="number"  style="width: 60px; border-style: double; margin-left: 10px;" :id="items.NS" :value="Detalles.Ncs[index]"  >
                       <div style="width: 20%;">R-ref</div>
                       <input type="text" :id="items.NS+'R'+items.APLICATION" :value="items.R_ref" style="width: 60px; border-style: double; margin-left: 10px;">
                    </v-card-tittle>               
                  </v-crad>
                </v-list>
      </v-card>
                


      </v-row>

          </v-window-item>

          <v-window-item value="adv">
            <v-card style="margin: 20px; padding: 30px;">
              <v-row>
              <v-select label="Terminales"
                  :items="['X', 'S']"
                ></v-select>
                <v-select label="Relaciones"
                  :items="['1-2', '1-3','1-4','1-5','2-3','2-4','2-5','3-4','3-5','4-5']"
                ></v-select>
              </v-row>
            </v-card>
            <v-card style="width: 100%; padding: 30px;">
              <v-row>
                  <v-card style="width: 40%; padding: 30px; padding-top: 40px;">
                      <v-card-tittle >Maximos y minimos de grafica de exitacion</v-card-tittle>
                      <v-row style="margin-top: 20px;">
                        MAX. <v-text-field label="Maximo"></v-text-field>
                      </v-row>
                      .
                      .
                      .
                      <v-row>
                        MIN. <v-text-field label="Minimo"></v-text-field>
                      </v-row>
                </v-card>
                <v-img src="http://irisiscore.ga/imagenes/chartExit.jpg" style="height: 320px; width: 60%;"></v-img>
             </v-row>
            </v-card>


            <v-card style="width: 100%;">
              <v-row>
                <v-card style="width: 60%;">
                    <v-table style="margin-bottom: 30px;">
                      <thead>
                        <tr>
                          <th v-for="item in tablapreview.horizontal" :key="item">{{ item }}</th>
                        </tr>
                      </thead>
                      <tbody  style="font-size: 11px; padding: 0; ">
                        <tr v-for="item in tablapreview.vertical" :key="item">
                          <th v-for="item in tablapreview.horizontal" :key="item">.</th>
                        </tr> 
                        
                      </tbody> 
                  </v-table>
                

                </v-card>
                <v-card style="width: 40%; padding: 30px;">
                  <v-row>
                    <v-switch color="blue" label="Azul" inset v-model="tablapreview.azul" @change="setestados('Azul', tablapreview.azul)"></v-switch>
                    <v-select label="Minimo"
                        :items="['1.0%', '5.0%','10%','20%','50%']"
                      ></v-select>
                  </v-row>     
                  <v-row>         
                       <v-switch color="green" label="Verde" inset v-model="tablapreview.verde"  @change="setestados('Verde', tablapreview.verde)"></v-switch>
                       <v-select label="Minimo"
                        :items="['120%', '100%','50%','20%','10%','5.0%']"
                      ></v-select>
                   </v-row>   
                  <v-switch color="red" label="Rojo" inset v-model="tablapreview.rojo"  @change="setestados('Rojo', tablapreview.rojo)"></v-switch>
                </v-card>
              </v-row>
            </v-card>

          </v-window-item>
      </v-window>
       
    </v-card>
   </v-row>

<div v-show="false" id="donwlds">
</div>


<v-dialog v-model="dialog.advertencia" transition="dialog-bottom-transition" width="auto"   >
      <v-card >
        <v-toolbar color="blue-grey-darken-2" :title="'Selecciona idioma de descarga de la OF '+OF " style="padding: 20px;"></v-toolbar>
        <v-row style="padding: 60px; margin: auto;"  >

              <v-btn variant="tonal"  @click="download('E', sectionView)" style="margin-right: 20px;"
              >Español</v-btn>


              <v-btn
              variant="tonal"
                @click="download('I',sectionView)"
              >English</v-btn>

        </v-row>
           
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="dialog.advertencia=false"  
              >Cancelar</v-btn> 

            </v-card-actions>
        </v-card>
</v-dialog>


<v-dialog v-model="dialog.confirmasion" transition="dialog-bottom-transition" width="auto"   >
      <v-card >
        <v-toolbar color="amber-darken-1" title="Accion requiere confirmasion" style="padding: 20px;"></v-toolbar>
        <v-row style="padding: 60px; margin: auto;"  >

          Se eliminaran todos los elementos de la orden {{ dialog.dto }}

        </v-row>
           
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="dialog.confirmasion=false"  
              >Cancelar</v-btn> 

              <v-btn
                variant="tonal"
                color="red" 
                @click="deleteof"  
              >Confirmar</v-btn> 

            </v-card-actions>
        </v-card>
</v-dialog>



<v-dialog v-model="dialog.firma" transition="dialog-bottom-transition" width="auto"   >
      <v-card >
        <v-toolbar color="blue-grey-darken-2"  :title="'Firmar orden' +  dialog.dto " style="padding: 20px;"></v-toolbar>
        <v-row style="padding: 60px; margin: auto;"  >

              
            <v-btn  @click="firmar('Revisado')" style="margin-right: 60px;">Revisar</v-btn>
            <v-btn  @click="firmar('Aprobado')">Aprobar</v-btn>
            <!-- Aprobado -->
        </v-row>
           
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="dialog.firma=false"  
              >Cancelar</v-btn> 

          

            </v-card-actions>
        </v-card>
</v-dialog>


<v-dialog v-model="dialog.share" transition="dialog-bottom-transition" width="auto"   >
      <v-card >
            <v-card style="padding: 40px;">
          Enlace: 
          http://irisiscore.com/client/index.html?'+{{this.OF}}

          <div class='footer'>
            <a :href="'http://irisiscore.com/client/index.html?'+this.OF" target="_blank"><button style="width: 30%; height: 40px; background: cyan; border-style: none; border-radius: 10px;">Ver orden</button></a>
          </div>
        </v-card>
        
           
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="dialog.share=false"  

              >Cancelar</v-btn>


              <!-- <v-btn
                variant="text"
                @click="compartir"  
              >Enviar</v-btn> -->


            </v-card-actions>
        </v-card>
</v-dialog>


<v-dialog v-model="dialog.ok" transition="dialog-bottom-transition" width="auto"   >
      <v-card >
        <v-toolbar color="green-darken-4" :title="'Exito' " style="padding: 20px;"></v-toolbar>
        <v-row style="padding: 60px; margin: auto;"  >

              {{dialog.mensaje}}
        </v-row>
           
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="dialog.ok = false"  
              >CERRAR</v-btn>


            </v-card-actions>
        </v-card>
</v-dialog>


<v-dialog v-model="dialog.error" transition="dialog-bottom-transition" width="auto"   >
      <v-card >
        <v-toolbar color="red-accent-4" :title="'Error' " style="padding: 20px;"></v-toolbar>
        <v-row style="padding: 60px; margin: auto;"  >

              {{dialog.mensaje}}
        </v-row>
           
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="dialog.error = false"  
              >CERRAR</v-btn>


            </v-card-actions>
        </v-card>
</v-dialog>

</template>

<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL
const urlRepor = process.env.VUE_APP_SERVICE_URLREPORT
export default { 
    data(){
        return{
          email: {
            From: "",
            Asunto: '',
            cuerpo: "Buen día estimado equipo de equipos eléctricos Core  Agradezco su confianza y paciencia ante el sistema implementado porque se ha preparado una serie de actualizaciones que buscan agilizar aún más su trabajo además de brindar  alginas herramientas adicionales que espero sean de utilizad y agreguen valor como empresa.   ¡Saludos!",
            para: "",
            valuesEmails: [],
            emailsfor: ['marcos.cpascacio@gmail.com', 'markcpz@gmail.com'],

          },
          ID: 0,
          carging: false,
          sectionLog: '',
          sectionView: '',
          dialog:{
             ok: false,
             share: false,
             advertencia: false,
             confirmasion: false,
             firma: false,
             error: false,
             mensaje: "",
             dto: "",
             dto2: ""
           },
           files: [],
           files2: [],
            headers: ['OF','Cliente', 'Cantidad', 'Modelo', 'OC', 'Fecha','Firma','Acciones'],
            produccion: [],
            ingenieria: [],
            zapata: [],
            ListMain: [],
            General: {
              data: [],
              modelo: "",
              no_serie: "",
              OC: "",
              FT: "",
              EG: "",
              CT: "",
              Cantidad: "",
              CLASIS: "",
              TPOTRANS: "",
              OF: "",
              PA: "",
              PI: "",
              Aplication: "",
              Clase: "",
              Frecuancia: "",
              Norma: "",
              Terminales: "",
              Relaciones: "1-2",
              Secundario: "",
              
            },
            Detalles: {
              series: [],
              data: [],
              Ncs: [],
              r_refs: []
            },
            OF: "",
            NS: "",
            clasedt: "",
            leng: "Español",
            tab: "prot",
            SRCprotocolo: "",
            tablapreview: {
              azul: true,
              verde: true,
              rojo: true,
              vertical: ['azul', 'verde','rojo'],
              horizontal: ['1.0%', '5.0%','10%','20%','50%', '100%', '120%']
            }
        }
    },
    created(){
      localStorage.getItem('section') == null ? localStorage.setItem('usser', null) : localStorage.getItem('section')
      this.sectionLog = localStorage.getItem('section')
      this.obtenerDatas()
      // console.log(this.sectionLog)
    },
    methods: {
      obtenerDatas(){
        axios.post(url+'/generalData.php?P=OBT') 
             .then((result) => {     
              this.produccion = result.data.Produccion
              this.ingenieria = result.data.Ingenieria
              this.zapata = result.data.zapata 


              if(this.sectionLog == 'Ingenieria'){                
                this.ingenieria.length > 0 ? this.sectionView = 'Ingenieria' : this.produccion.length > 0 ? this.sectionView = 'produccion' : this.sectionView = 'corezapata'
              }else{
                this.sectionView = localStorage.getItem('section')
              }

              // console.log(result.data)


              if(this.sectionView == 'Ingenieria'){
                this.ListMain = this.ingenieria
              }
              else if(this.sectionView == 'produccion'){
                this.ListMain = this.produccion
              }
              else{
                this.ListMain = this.zapata
              }

            })   
      },
      obtenerxOF(){
        this.Detalles.series =[];
        // console.log(this.ID);
        let datas = { vista: this.sectionView, OF : this.OF, ID: this.ID,APL: this.clasedt}

        axios.post(url+'/generalData.php?P=BOF',datas,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
             .then((result) => {     
              if (result.data.General.length> 0){
                let listas = this.Detalles.series
              var d = result.data.General[0]
            

              result.data.Detalles.forEach(function(elemt){
                 if(listas.includes(elemt.NS)){
                    // console.log(elemt.NS)
                 }else{
                    listas.push(elemt.NS)
                 }
                 
              })
              // console.log(result.data.General[0listas].APLICATION)
              // console.log(d.NUMSER)
              this.clasedt = result.data.General[0].APLICATION


              

   
              this.Detalles.data = result.data.Detalles ?? []            
              this.NS = d.NUMSER+"-01";
              this.SRCprotocolo = this.sectionLog != 'corezapata' ? urlRepor+"/PROTOCOLO.php?NS="+this.NS+"&A=O&L=E&V="+this.sectionView+"&P="+this.ID+"" : urlRepor+"/ZAPATA.php?NS="+this.NS+"&A=O&L=E&V="+this.sectionView+"&P="+this.ID+"" 
              this.General.modelo = d.MODELO 
              this.General.no_serie = d.NUMSER
              this.General.OC = d.OC 
              this.General.FT = d.FACTREM 
              this.General.EG = d.EGENERAL 
              this.General.CT = d.CLIENTE 
              this.General.Cantidad = d.CANTIDAD   
              this.General.CLASIS = d.CLASAIS 
              this.General.TPOTRANS = d.TPOTRANS 
              this.General.OF = d.OF 
              this.General.Aplication = d.APLICATION 
              this.General.PA = d.PA 
              this.General.PI = d.PI 
              this.General.Clase = d.CLASE 
              this.General.Frecuancia = d.FRECUENCIA 
              this.General.Norma = d.NORMA 
              this.General.Secundario = d.SECUNDARIO
              this.General.Terminales = d.TERMINALES
              this.General.Relaciones = d.RELACIONES

              var numeros = [];
              result.data.Detalles.forEach(function(elemt){
                var sepns = elemt.NS.split("-")
                numeros.push(sepns[1])
              })

              this.Detalles.Ncs = numeros
                              
               }else{
                this.General.modelo = ""
                this.General.no_serie = ""
                this.General.OC = ""
                this.General.FT = ""
                this.General.EG = ""
                this.General.CT = ""
                this.General.Cantidad = ""
                this.General.CLASIS = ""
                this.General.TPOTRANS = ""
                this.General.OF = ""
                this.General.Aplication = ""
                this.General.PA = ""
                this.General.PI = ""
                this.General.Clase = ""
                this.General.Frecuancia = ""
                this.General.Norma = ""
                this.General.Secundario = ""
                this.General.Terminales = ""
                this.General.Relaciones = ""

               }
            })   
      },
      geturl(ns, vista, plicat){
       
        if(vista){
          this.leng == 'Español' ? this.leng = 'Ingles': this.leng = 'Español'
        }

        console.log(plicat)


        const lenguage = this.leng == 'Español' ? 'E' : 'I'
        // if(this.sectionView == 'corezapata'){
        //      this.SRCprotocolo = "https://coresistem.com/Reports/ZAPATA.php?NS="+ns+"&A=O&L="+lenguage+"&V="+this.sectionView+""}
        //      else{
              this.SRCprotocolo = urlRepor+"/PROTOCOLO.php?NS="+ns+"&A=O&L="+lenguage+"&V="+this.sectionView+"&P="+this.ID+""
            //  }
       console.log( this.SRCprotocolo)
  
      },
      setestados(dato, estado ){
        if(dato ==  'verde'|| dato == 'rojo'){
             if(estado){
                  this.tablapreview.vertical.push(dato)
             }else{
                this.tablapreview.vertical.pop()
             }
        }


      },
      verventata(){
        var conetn = document.getElementById('conetido')
        if(this.tab == 'gen' || this.tab == 'adv'){
          conetn.classList.add('contenApliado')
          document.querySelector(".contenido").style.width = "100%";
        }
        else{
          
          conetn.classList.remove('contenApliado')
          conetn.style.width = "85";
          document.querySelector(".contenido").style.width = "85%";
        }
       
      },
      async download(lenguage, vista){
        this.carging = true;
        this.Detalles.data.forEach(function(elemento){
          var ns = elemento.NS
          var ur = urlRepor+"/PROTOCOLO.php?NS="+ns+"&A=D&L="+lenguage+"&V="+vista+""
          //  console.log(ns)
          

          var iframe = document.createElement('iframe')
          iframe.setAttribute("src", ur)
          document.getElementById("donwlds").appendChild(iframe)
        })


        var data= {OF : this.OF, vista: vista }

       await axios.post(url+'/share.php?P=DWZ',data,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
             .then((result) => {
              var iframe = document.createElement('iframe')
              // iframe.id="zipw";
              iframe.setAttribute("src", result.data)
              document.getElementById("donwlds").appendChild(iframe)
            })

       await axios.post(url+'/share.php?P=DLT',data,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
             .then((result) => {
              console.log(result)
             
            })

            this.carging = false; 
        this.dialog.advertencia = false
      },
      guardar(){
        this.carging = true;
        var numsSeries = []
        var rRefs = []
        var urlSol
        // console.log()
        var serie= this.General.no_serie
        if(this.OF != 'nuevo'){
          urlSol = url+'/generalData.php?P=GDM'
          this.Detalles.data.forEach(function(el){
            
          var num = el.NS+'R'+el.APLICATION    
          console.log(num)    
          numsSeries.push(serie+'-'+document.getElementById(el.NS).value)
          rRefs.push(document.getElementById(num).value)
        })

        }else{
          urlSol = url+'/generalData.php?P=GDN'
        }
        
        // console.log(rRefs);

        var archuvos = document.getElementById('files')

           console.log(this.ID)
            let formdata = new FormData();
            formdata.append("ID" , this.ID)
              formdata.append("modelo" , this.General.modelo)
              formdata.append("no_serie" , this.General.no_serie)
              formdata.append("OC" , this.General.OC)
              formdata.append("FT" , this.General.FT)
              formdata.append("EG" , this.General.EG)
              formdata.append("CT" , this.General.CT)
              formdata.append("Cantidad" , this.General.Cantidad)
              formdata.append("CLASIS" , this.General.CLASIS)
              formdata.append("TPOTRANS" , this.General.TPOTRANS)
              formdata.append("OF" , this.General.OF)             
              formdata.append("PA" , this.General.PA)
              formdata.append("PI" , this.General.PI)
              formdata.append("Aplication" , this.General.Aplication)
              formdata.append("Clase" , this.General.Clase)
              formdata.append("Frecuancia" , this.General.Frecuancia)
              formdata.append("Norma" , this.General.Norma)
              formdata.append("Series" , numsSeries)
              formdata.append("rRefs" , rRefs)
              formdata.append("vista" , this.sectionView)
              formdata.append("usuario" ,  localStorage.getItem('usser'))
              formdata.append("terminales" ,  this.General.Terminales)
              formdata.append("relaciones" ,  this.General.Relaciones)
              formdata.append("secundario" ,  this.General.Secundario)

              if(this.OF == 'nuevo'){
              for(let file of archuvos.files){
                formdata.append('files[]',file) 
              }
              if(this.General.Secundario == 'doble'){
                      var archuvos2 = document.getElementById('files2')
                    for(let file2 of archuvos2.files){
                      formdata.append('files2[]',file2) 
                    }
              }
             
            }
            


            axios.post(urlSol,formdata,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
             .then((result) => {

             console.log(result.data)
              this.obtenerxOF()

              this.carging = false;
                // this.tab = 'prot'
                // this.OF = this.General.OF
                // // this.ID = this.ID
                // this.verventata() 
                // this.obtenerDatas()
                // this.dialog.ok = true
                // this.dialog.mensaje = "Orden de factura "+result.data.OF+" agregada con exito"
                
                // this.obtenerxOF();
              
            })


      },
      nuevo(){
        this.tab='gen';
        this.OF = 'nuevo'; 
        this.ID = 0;
        this.obtenerxOF();
      },
      compartir(){
         var data = {
           From : this.email.From
          ,Asunto : this.email.Asunto
          ,cuerpo : this.email.cuerpo
          ,para : this.email.para
          ,cc : this.email.valuesEmails,
           OF: this.OF
         }
         


        axios.post(url+'/shareEmail.php?P=SOF',data,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}}) 
             .then((result) => {
              console.log(result.data)
              // this.obtenerxOF()
            })
      },
      deleteof(){
        var data = {
          OF: this.dialog.dto,
          vista: this.sectionView
        }

           axios.post(url+'/generalData.php?P=DLT',data,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
             .then((result) => {
              if(result.data == 'OK'){
                this.dialog.confirmasion = false
                this.dialog.ok = true
                this.obtenerDatas()
                this.dialog.mensaje = 'Orden '+this.dialog.dto+' se ha eliminado con exito'
              }
            })

      },
      firmar(tipo){

        var data = {
          nombre: localStorage.getItem('usser'),
          tipo: tipo,
          OF: this.dialog.dto
        }

           axios.post(url+'/loginsAndValid.php?P=FIR',data,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
             .then((result) => {
              if(result.data.tipo == 'Exito'){
                this.dialog.firma = false
                this.dialog.ok = true
                this.obtenerDatas()
                this.dialog.mensaje = 'Orden formada con exito ';
              }if(result.data.tipo == 'error'){
                this.dialog.firma = false
                this.dialog.error = true
                this.dialog.mensaje = 'No se tiene autorizacion para firmar de '+tipo;
              }
            })

      }
      
    }
}
</script>

<style >
.contentof{
  width: 100%;
  height: 84vh;
}

.iframeRpt{
  width: 84%;
  height: 84vh;
  /* margin-right: 30px; */
}

td{
  justify-content: start;
  text-align: start;
}

.col1{
  width: 12%;
  font-size: 12px;
}

.contenido{
  width: 85%;
}

.contenApliado{
  width: 100%;
  /* padding: 20px; */
}


.container {
      width: 90%;
      margin-left: 5%;
      margin: 0 auto;
    }
    .bg-dark {
      background: #010037;
      margin-top: 40px;
      padding: 20px 0;
    }
    .alert {
      font-size: 1.5em;
      position: relative;
      padding: .75rem 1.25rem;
      margin-bottom: 2rem;
      border: 1px solid transparent;
      border-radius: .25rem;
    }
    .alert-primary {
      color: #010037;
      background-color: #010037;
      border-color: #010037;
      margin-left: 30%;
    }

    .img-fluid {
      max-width: 100%;
      height: auto;
    }

    .mensaje {
      width: 80%;
      font-size: 20px;
      margin: 0 auto 40px;
      color: #eee;
    }

    .texto {
      margin-top: 20px;
    }

    .footer {
      width: 100%;
      background: #010037;
      text-align: center;
      color: #ddd;
      padding: 10px;
      font-size: 14px;
    }

    .footer span {
      text-decoration: underline;
    }

</style>
