<template>
  
  <v-toolbar density="compact"  color="blue-darken-4">
    <!-- <v-app-bar-nav-icon  @click="ocultarMenu "></v-app-bar-nav-icon> -->
   <!-- <a href="">Verion Anterior</a> -->
    <v-toolbar-title>CORE RPT</v-toolbar-title>

    <v-spacer></v-spacer>


    {{ user }}
    <v-btn icon @click="ocultarMenuUser">
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </v-toolbar>



  <v-card min-width="300" width="300" v-if="menuUser"  style="position: absolute;  right: 10px; z-index: 1;">
        <v-list>
          <v-list-item
            prepend-avatar="https://coresistem.com/imagenes/corerpt.jpg"
            :title="user"
            :subtitle="section"
          >
           
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item @click="cerrarses">
           Cerrar sesion
          </v-list-item>

          
        </v-list>

      </v-card>

  <v-layout v-if="menutal" >
    <v-navigation-drawer class="menuLat" color="blue-darken-4" theme="dark" >  
    
      <v-list color="transparent" class="items">
        <v-list-item prepend-icon="mdi-view-dashboard" title="General" @click="openContent('general-page')"></v-list-item>
        <v-list-item prepend-icon="mdi-cellphone" title="Analiticos" @click="openContent('g-content-app')"></v-list-item>          
        <!-- <v-list-item prepend-icon="mdi-apps" title="Servicios" @click="openContent('Servicios')"></v-list-item>
        <v-list-item prepend-icon="mdi-hammer-wrench" title="Mantenimiento" @click="openContent('Mantenimiento')"></v-list-item> -->
      </v-list>

    
    </v-navigation-drawer>
  </v-layout>


</template>

<script>

export default {

data(){
  return{
    menutal: false,
    menuUser: false,
    user: localStorage.getItem('usser'),
    section: localStorage.getItem('section')
    
  }
},
methods: {
  ocultarMenu(){

      this.menutal = !this.menutal
      this.menutal == false ? this.mosSubmen = false : ''
    },

    ocultarMenuUser(){

      this.menuUser = !this.menuUser
      // this.menuUser == false ? this.mosSubmen = false : ''
      },
      cerrarses(){
        localStorage.removeItem('usser')
        window.location.reload()
      },
      openContent(page){
        this.menutal = false 
        this.menuActiv = page
        this.$emit('menuactiv', page)
    },
}


}
</script>
<style>
.menuLat{
  margin-top: 47px;
}
</style>