<template>

  <div v-if="!session" class="loggin">

    <v-card class="logg">
     
      <v-text-field
        label="Usuario"
        prepend-icon="mdi-account"
        variant="solo-filled"
        v-model="user"
      > </v-text-field>
      <v-text-field
        label="Contraseña"
        prepend-icon="mdi-lock"
        variant="solo-filled"
        type="password"
        v-model="password"
      > </v-text-field>

      <v-btn variant="outlined" color="#fff" style="background: #0074c7;" @click="ligin">
        Ingresar
      </v-btn>

    </v-card>


  </div>
  <div v-if="session">
      <menu-m @menuactiv="onMenu" >
      </menu-m>

      <v-card class="content">
        <component :is="componentSel"></component>
       
      </v-card>
   </div>



   <v-dialog v-model="advertencia" transition="dialog-bottom-transition" width="auto"   >
      <v-card >
        <v-toolbar color="blue-grey-darken-2" title="Error" style="padding: 20px;"></v-toolbar>
        <v-row style="padding: 60px; margin: auto;"  >

             Verifica tu usuario y Contraseña e intenta de nuevo 

        </v-row>
           
            <v-card-actions class="justify-end">
              <v-btn
                variant="text"
                @click="advertencia=false"  
              >Cerrar</v-btn> 

            </v-card-actions>
        </v-card>
</v-dialog>

</template>

<script>
import MenuM from './components/menuM.vue'
import GeneralPage from './screens/generalPage.vue'
import axios from "axios";
import DataAnalitics from './screens/dataAnalitics.vue';
const url = process.env.VUE_APP_SERVICE_URL
export default {
  name: 'App',
  data(){
    return{
      componentSel: "general-page",
      contenido: "",
      session: false,
      user: "",
      password: "",
      advertencia: false
    }    
  },
  components: {
    MenuM,
    GeneralPage,
    DataAnalitics
  },
  created(){   
    const user = localStorage.getItem('usser')
    console.log(user)
    user == null ? this.session = false : this.session = true   
    
    
  }, 
  methods: { 
    ligin(){
      let datas = { user: this.user, password : this.password}
      console.log(datas)
      axios.post(url+'/loginsAndValid.php?P=LGI',datas,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}}) 
              .then((result) => {    
              console.log(result.data.login)
              if(result.data.login){
                this.session = result.data.Login
              localStorage.setItem('usser', result.data.name)
              localStorage.setItem('section', result.data.section)
              window.location.reload()

              }else{
                this.advertencia  = true
              }
              
            }) 

    },
    onMenu(mn){
      this.contenido = mn          
      this.componentSel = mn     
      console.log(this.componentSel)
    },
  }


 
}
</script>

<style>

#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 00px;
}


.content{
  width: 99%;
  margin-left:0.5%;
  margin-top: 5px;
  border-style: double;
}

.loggin{
  width: 100%;
  height: 100vh;
  background-image: url(https://coresistem.com/imagenes/baklogin.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}

.logg{
  width: 50%;
  padding: 10%;
  border-radius: 20px;
  height: 60vh;
  margin-top: 20vh;
  background: #9a959580;
}
</style>
